import { OrganizationChart as OrgChart } from 'primereact/organizationchart';
import React, { useRef, useEffect, useState } from "react";
import avatar from "assets/avatar.png";
import { getUrl } from "aws-amplify/storage";
import { CircularProgress, Box, Typography, Button } from "@mui/material";

const OrganizationChart = ({ user, userData, orgChartMembers, loadingCompanyData }) => {
    const [zoomLevel, setZoomLevel] = React.useState(0.8);
    const [avatarUrls, setAvatarUrls] = React.useState({});
    const [allChartsData, setAllChartsData] = useState([]);

    const scrollContainerRef = useRef(null);

    const handleZoomIn = () => setZoomLevel(prev => Math.min(prev + 0.1, 2));
    const handleZoomOut = () => setZoomLevel(prev => Math.max(prev - 0.1, 0.5));

    const fetchAvatarUrl = async (avatarPath) => {
        if (!avatarPath) return null;

        try {
            const encodedFileName = encodeURIComponent(avatarPath);
            const filePath = `public/avatar/${encodedFileName}`;
            const { url } = await getUrl({
                path: filePath,
                expires: 900,
                validateObjectExistence: true,
            });
            return url ? url.href : null;
        } catch (error) {
            console.error("Error fetching avatar URL:", error);
            return null;
        }
    };

    const prefetchAvatarUrls = async (members) => {
        const avatarPromises = members.map(async (member) => {
            if (member.avatar && !avatarUrls[member.user_id]) {
                const url = await fetchAvatarUrl(member.avatar);
                return { userId: member.user_id, url };
            }
            return null;
        });

        const avatarResults = await Promise.all(avatarPromises);
        const newAvatarUrls = avatarResults.reduce((acc, item) => {
            if (item) acc[item.userId] = item.url;
            return acc;
        }, {});
        setAvatarUrls((prev) => ({ ...prev, ...newAvatarUrls }));
    }; 

    const transformOrgChartData = async (members, level = 0) => {
        const transformNode = async (node, level) => ({
            expanded: true,
            type: 'person',
            className: `text-white`,
            style: {
                borderRadius: '15pt',
                minHeight: '180px',
                border: '1px solid #D9E4EC',
            },
            data: {
                image: avatarUrls[node.user_id] || avatar,
                name: node?.full_name || "Unknown",
                title: node?.company_role_name || node?.role || "No Title",
                teams: node?.teams,
            },
            children: Array.isArray(node?.children)
                ? await Promise.all(node.children.map((child) => transformNode(child, level + 1)))
                : [],
        });

        return Promise.all(members.map((member) => transformNode(member, level)));
    };

    useEffect(() => {
        const fetchData = async () => {
            if (!orgChartMembers) return;
            await prefetchAvatarUrls(orgChartMembers); // Fetch avatar URLs first
            const allData = await Promise.all(
                orgChartMembers.map((rootNode) => transformOrgChartData([rootNode]))
            );
            setAllChartsData(allData);
        };

        fetchData();
    }, [orgChartMembers]);

    const nodeTemplate = (node) => {
        if (node.type === 'person') {
            return (
                <div className="flex flex-col items-center px-2 py-6 min-w-48 min-h-52">
                    <img
                        alt="avatar"
                        src={node.data.image}
                        className="mb-3 w-12 h-12 rounded-full z-10"
                    />
                    <span
                        style={{
                            color: 'black', fontSize: 21, fontFamily: 'Poppins', fontWeight: 400, wordWrap: 'break-word',
                        }}
                    >
                        {node.data.name}
                    </span>
                    <span
                        style={{
                            color: '#A3A3A3', fontSize: 14, fontFamily: 'Lexend', fontWeight: 200, wordWrap: 'break-word',
                        }}
                    >
                        {node.data.title}
                    </span>
                    {Array.isArray(node.data.teams) && node.data.teams.length > 0 && (
                        <div className="text-sm text-gray-200">
                            {node.data.teams.map((team, index) => (
                                <div key={index} className="flex flex-col items-center">
                                    <span
                                        style={{
                                            color: '#A3A3A3', fontSize: 14, fontFamily: 'Lexend', wordWrap: 'break-word', textTransform: 'capitalize',
                                        }}
                                    >
                                        {team.team_name}
                                    </span>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            );
        }
        return <div className="p-6">{node.label}</div>;
    };
    

    // Center the org chart horizontally on mount
    useEffect(() => {
        if (scrollContainerRef.current) {
            const { scrollWidth, clientWidth } = scrollContainerRef.current;
            scrollContainerRef.current.scrollLeft = (scrollWidth - clientWidth) / 2;
        }
    }, [orgChartMembers]);

    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                overflow: "hidden",
            }}
        >
            {/* Zoom controls */}
            <Box sx={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        mb: 2,
                        border: "1px solid black",
                        borderRadius: "4px",
                    }}
                >
                    <Button
                        sx={{
                            color: "black",
                            fontSize: "16px",
                            padding: "4px",
                            minWidth: "40px",
                        }}
                        onClick={handleZoomIn}
                    >
                        +
                    </Button>
                    <Button
                        sx={{
                            color: "black",
                            fontSize: "16px",
                            padding: "4px",
                            minWidth: "40px",
                        }}
                        onClick={handleZoomOut}
                    >
                        -
                    </Button>
                </Box>
            </Box>
    
            {/* Scrollable container for multiple charts */}
            <Box
                ref={scrollContainerRef}
                sx={{
                    width: "100%",
                    height: "100%",
                    overflowX: "auto", // Allow horizontal scrolling
                    overflowY: "auto", // Allow vertical scrolling
                    display: "flex",
                    justifyContent: "flex-start",
                    paddingBottom: "8px",
                    paddingRight: "8px",
                    "&::-webkit-scrollbar": {
                        width: "8px",
                        height: "8px",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "rgb(207, 207, 207)",
                        borderRadius: "4px",
                      },
                      "&::-webkit-scrollbar-thumb:hover": {
                        backgroundColor: "rgb(207, 207, 207)",
                      },
                      "&::-webkit-scrollbar-track": {
                        backgroundColor: "transparent",
                      }
                    //   scrollbarWidth: "none", // Firefox
                    //   "&::-webkit-scrollbar": {
                    //       // Chrome, Safari, and Edge
                    //       display: "none",
                    //   },
                }}
            >
                {/* Show loading spinner if data is not loaded */}
                {loadingCompanyData || !orgChartMembers ? (
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        width="100%"
                    >
                        <CircularProgress />
                        <Typography variant="body1" sx={{ marginTop: 2 }}>
                            Loading Organization Chart...
                        </Typography>
                    </Box>
                ) : (
                    allChartsData.map((chartData, index) => (
                        <Box
                            key={index}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                            }}
                        >
                            <Box
                                sx={{
                                    transform: `scale(${zoomLevel})`, // Apply scaling only to the chart content
                                    transformOrigin: "top center",
                                    transition: "transform 0.2s ease-in-out", // Smooth zoom transitions
                                }}
                            >
                                <OrgChart
                                    value={chartData} // Pass the root node's data
                                    nodeTemplate={nodeTemplate}
                                    pt={{
                                        root: { style: {} },
                                        table: { style: { width: "auto" } },
                                        nodeToggler: {
                                            className: "flex justify-center bg-white rounded-full",
                                        },
                                        lineDown: { style: { background: "#D9E4EC" } },
                                    }}
                                />
                            </Box>
                        </Box>
                    ))
                )}
            </Box>
        </Box>
    );
    
    
};

export default OrganizationChart;