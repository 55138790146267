import { Modal, Box, Button, Typography, CircularProgress, FormControl, InputLabel, Select, MenuItem, Checkbox, FormControlLabel, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import axios from "axios";
import ConfirmButton from "components/ConfirmButton";
import DeclineButton from "components/DeclineButton";
import { toast } from "react-toastify";
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
import { KeyboardArrowDown } from "@mui/icons-material";
import { generateClient } from "aws-amplify/data";

// const resourceClient = generateClient({authMode: 'userPool'});
/**
 * @type {import('aws-amplify/data').Client<import('../amplify/data/resource').Schema>}
 */
const resourceClient = generateClient({ authMode: "userPool" });



const ActionOnUserModal = ({ user, userData, openActionOnUserModal, setOpenActionOnUserModal, companyMembers, internalCompanyMembers, companyInformation, teamsInformation, selectedUser, setSelectedUser, fetchCompanyData, loadingCompanyData, myPrivateUsers }) => {
    const [userTeams, setUserTeams] = useState([]);
    const [loadingSaveChanges, setLoadingSaveChanges] = useState(false);
    const [loadingResendInvite, setLoadingResendInvite] = useState(false);
    const [title, setTitle] = useState(selectedUser?.title || '');
    const [savingTitle, setSavingTitle] = useState(false);
    const [selectedTeam, setSelectedTeam] = useState('');
    const [addingToTeam, setAddingToTeam] = useState(false);
    const [removingTeam, setRemovingTeam] = useState(null);
    const [selectedManager, setSelectedManager] = useState(selectedUser?.manager_user_id || "");
    const [savingManager, setSavingManager] = useState(false);
    const [markingAsAdmin, setMarkingAsAdmin] = useState(null);
    const [removingAdminRole, setRemovingAdminRole] = useState(null);
    const [initialTitle, setInitialTitle] = useState('');
    const [loadingPrivateUserRemoval, setLoadingPrivateUserRemoval] = useState(false);
    console.log("selected user: ", selectedUser);
    console.log("int comp mems: ", internalCompanyMembers)

    const [confirmChecked, setConfirmChecked] = useState(false);
    const [keepData, setKeepData] = useState(false);
  
    const handleConfirmChange = (event) => {
      setConfirmChecked(event.target.checked);
    };
  
    const handleKeepDataChange = (event) => {
      setKeepData(event.target.checked);
    };
  
    const handleRemovePrivateUser = async () => {
        if (confirmChecked) {
          try {
            setLoadingPrivateUserRemoval(true);
      
            const response = await axios.post(
              `${process.env.REACT_APP_API_ENDPOINT}/zoom/remove-private-user`,
              {
                user_id: selectedUser.user_id,
                company_id: companyInformation?.company_id,
                caller_id: user?.sub,
                keep_data: keepData
              }
            );
      
            if (response.status === 200) {
              // Decrement the seat count only on successful response
              await resourceClient.mutations.decrementCoacheeSeatCount({
                user_id: companyInformation.owner_user_id,
              });
              toast.success("Private user removed successfully.");
              fetchCompanyData();  // Refresh data after successful removal
            } else {
              // Display error if status isn't 200
              toast.error("Failed to remove private user.");
            }
      
            setLoadingPrivateUserRemoval(false);
            handleCloseActionOnUserModal();
      
          } catch (error) {
            setLoadingPrivateUserRemoval(false);
            toast.error("An error occurred while removing the private user.");
          }
        }
      };
      

    const CustomIcon = (props) => (
        <KeyboardArrowDown {...props} style={{ color: 'black' }} />
      );
 
    useEffect(() => {
        // Initialize state with the selected user's current team and admin status
        if (selectedUser && selectedUser.teams) {
            setUserTeams(selectedUser.teams);
            setTitle(selectedUser?.title || '');
            setInitialTitle(selectedUser?.title || '');
        }
        if (selectedUser?.manager_user_id) {
            setSelectedManager(selectedUser.manager_user_id);
        }
    }, [selectedUser]);

    const handleCloseActionOnUserModal = () => {
        setTitle('')
        setSelectedTeam('')
        setSelectedUser([]);
        setAddingToTeam(false);
        setLoadingResendInvite(false);
        setConfirmChecked(false);
        setKeepData(false);
        setOpenActionOnUserModal(false);
        setSelectedManager(null)
        setUserTeams([]);
    };

    const handleTitleSave = async () => {
        setSavingTitle(true);
        try {
            await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/zoom/update-user-title`, {
                user_id: selectedUser.user_id,
                new_title: title,
                company_id: companyInformation?.company_id,
                caller_id: user?.sub
            });
            console.log("body: ",selectedUser.user_id, title, companyInformation?.company_id)
            toast.success("Title updated successfully");
            fetchCompanyData();
        } catch (error) {
            console.error("Error updating title:", error);
            toast.error("Error while updating title");
        } finally {
            setSavingTitle(false);
        }
    };

    const handleRemoveTeam = async (teamId) => {
        try {
            setRemovingTeam(teamId)
            await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/zoom/remove-user-from-team`, {
                user_id: selectedUser.user_id,
                team_id: teamId,
                company_id: companyInformation?.company_id,
                caller_id: user?.sub
            });
            toast.success("User removed from team successfully");
            fetchCompanyData();
        } catch (error) {
            console.error("Error removing user from team:", error);
            toast.error("Error while removing user from team");
        }finally{
            setRemovingTeam(null)
        }
    };

    const handleMarkAsAdmin = async (teamId) => {
        try {
            setMarkingAsAdmin(teamId);
            await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/zoom/mark-user-as-team-admin`, {
                user_id: selectedUser.user_id,
                team_id: teamId,
                company_id: companyInformation?.company_id,
                caller_id: user?.sub
            });
            toast.success("User marked as admin successfully");
            fetchCompanyData();
        } catch (error) {
            console.error("Error marking user as admin:", error);
            toast.error("Error while marking user as admin");
        }finally{
            setMarkingAsAdmin(null)
        }
    };

    const handleMarkAsTeamMember = async (teamId) => {
        try {
            setRemovingAdminRole(teamId)
            await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/zoom/mark-user-as-team-member`, {
                user_id: selectedUser.user_id,
                team_id: teamId,
                company_id: companyInformation?.company_id,
                caller_id: user?.sub
            });
            toast.success("User marked as team member successfully");
            fetchCompanyData();
        } catch (error) {
            console.error("Error marking user as team member:", error);
            toast.error("Error while removing admin role");
        } finally{
            setRemovingAdminRole(null);
        }
    };

    const handleResendInvite = async () => {
        try {
            if (!companyInformation) {
                throw new Error("Company information is not available");
            }
            setLoadingResendInvite(true);
            await axios.put(`${process.env.REACT_APP_API_ENDPOINT}/zoom/resend-invite`, {
                invited_user_id: selectedUser.user_id,
                user_id: user?.sub,
                company_id: companyInformation?.company_id,
                company_name: companyInformation?.company_name
            });
            toast.success("Invitation sent successfully");
        } catch (error) {
            console.error("Error resending invite:", error);
            alert(error.message || "Failed to resend invite. Please try again.");
        } finally {
            setLoadingResendInvite(false);
        }
    };

    const handleAddToTeam = async () => {
        if (!selectedTeam) return;
        setAddingToTeam(true);
        try {
          await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/zoom/add-user-to-team`, {
            user_id: selectedUser.user_id,
            team_id: selectedTeam,
            company_id: companyInformation?.company_id,
            caller_id: user?.sub
          });
          toast.success("User added to team successfully");
          fetchCompanyData(); // Refresh data
          setSelectedTeam(''); // Clear selection
        } catch (error) {
          console.error("Error adding user to team:", error);
          toast.error("Error while adding user to team");
        } finally {
          setAddingToTeam(false);
        }
      };

      const checkCircularReference = (currentUser, selectedManager, allMembers) => {
        const checkLoop = (userId, targetManagerId) => {
            if (!userId) return false;
            
            let managerId = allMembers.find(member => member.user_id === userId)?.manager_user_id;
            
            while (managerId) {
                if (managerId === targetManagerId) return true; // Circular reference detected
                managerId = allMembers.find(member => member.user_id === managerId)?.manager_user_id;
            }
            
            return false;
        };
    
        // Direct circular reference check
        if (checkLoop(currentUser.user_id, selectedManager) || checkLoop(selectedManager, currentUser.user_id)) {
            return true;
        }
        return false;
    };
    
    // Usage
    const handleSaveManager = async () => {
        if (checkCircularReference(selectedUser, selectedManager, internalCompanyMembers)) {
            toast.error("Circular management relationship detected. Please select a different manager.");
            return;
        }
    
        // Proceed with manager assignment if no circular relationship
        setSavingManager(true);
        try {
            await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/zoom/update-manager`, {
                user_id: selectedUser.user_id,
                manager_user_id: selectedManager || null,
                company_id: companyInformation?.company_id,
            });
            toast.success("Manager updated successfully");
            fetchCompanyData(); // Refresh company data to reflect changes
        } catch (error) {
            console.error("Error updating manager:", error);
            toast.error("Error while updating manager");
        } finally {
            setSavingManager(false);
        }
    };

    const checkCircularRelationship = (currentUserId, potentialManagerId, allMembers) => {
        const visited = new Set();
    
        // Walk up the hierarchy to see if we encounter the current user ID, which would create a loop
        let managerId = potentialManagerId;
        while (managerId) {
            if (managerId === currentUserId) return true; // Circular relationship detected
            if (visited.has(managerId)) break; // Prevent infinite loop if something goes wrong
            visited.add(managerId);
    
            // Find the next manager up in the chain
            managerId = allMembers.find(member => member.user_id === managerId)?.manager_user_id;
        }
    
        return false;
    };

    return (
        <Modal
            open={openActionOnUserModal}
            onClose={handleCloseActionOnUserModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    position: "absolute",
                    backgroundColor: "white",
                    color: "black",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 600,
                    maxWidth: "calc(100% - 40px)",
                    maxHeight: "80%",
                    overflowY: "hidden",
                    boxShadow: 24,
                    borderRadius: "30px",
                    padding: "24px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                }}
            >
                <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "16px" }}>
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography sx={{ color: 'black', fontSize: 36, fontFamily: 'Lexend', fontWeight: '300', wordWrap: 'break-word'}}>
                            Editing: {selectedUser?.full_name || ""}
                        </Typography>
                        {selectedUser?.invite_status && selectedUser?.invite_status === 'pending' && (
                            <Button
                                sx={{
                                    color: 'black', fontSize: 12, fontFamily: 'Poppins', fontWeight: '500', textTransform: 'capitalize', letterSpacing: "0.05em", borderRadius: 50, border: '1px black solid', width: "200px", height: "30px", lineHeight: "18px",
                                    transition: "all 0.3s",
                                    "&:hover": {
                                    cursor: "pointer",
                                    background: "black",
                                    borderColor: "black",
                                    color: "white",
                                    },
                                    "&:hover *": {
                                    color: "white",
                                    },
                                    "&:hover svg *": {
                                    stroke: "white",
                                    },
                                }}
                                onClick={handleResendInvite}
                                disabled={loadingResendInvite || loadingCompanyData}
                            >
                                {loadingResendInvite ? <CircularProgress size={15} style={{ color: "black" }} /> : "Resend Invite"}
                            </Button>
                        )}
                    </Box>

                    <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                        <Typography sx={{color: 'black', fontSize: 12, fontFamily: 'Lexend', fontWeight: '700', textTransform: 'capitalize', letterSpacing: 2, wordWrap: 'break-word'}}>
                            Title
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center", gap: "16px"}}>
                            <TextField
                                placeholder="Title"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                fullWidth
                                InputLabelProps={{ style: { color: "#4F4F4F" }, shrink: false }}
                                InputProps={{
                                    style: { color: "black", borderColor: "1px #C4CAD4 solid", borderRadius: 16 },
                                    sx: {
                                        '& .MuiOutlinedInput-notchedOutline': { borderColor: '#C4CAD4' },
                                        '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#C4CAD4' },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '1px #C4CAD4 solid' },
                                    },
                                }}
                            />
                            <Button
                                onClick={handleTitleSave}
                                disabled={savingTitle || loadingCompanyData || title === initialTitle}
                                sx={{ 
                                    color: 'black',
                                    fontFamily: "Poppins",
                                    fontWeight: '500',
                                    border: '1px solid #000',
                                    borderRadius: 50,
                                    padding: '6px 12px',
                                    letterSpacing: "0.05em",
                                    lineHeight: "18px",
                                    textTransform: 'capitalize',
                                    '&.Mui-disabled': {
                                    color: 'black', // Ensure text remains black when disabled
                                    borderColor: '#000', // Ensure border remains visible
                                    opacity: 0.5, // Optional: adjust opacity to indicate disabled state
                                    },
                                    transition: "all 0.3s",
                                    "&:hover": {
                                    cursor: "pointer",
                                    background: "black",
                                    borderColor: "black",
                                    color: "white",
                                    },
                                    "&:hover *": {
                                    color: "white",
                                    },
                                    "&:hover svg *": {
                                    stroke: "white",
                                    },
                                 }}
                            >
                                {savingTitle ? <CircularProgress size={15} style={{ color: "black" }} /> : "Save"}
                            </Button>
                        </Box>
                    </Box>

                    
                {selectedUser?.role && selectedUser?.role !== 'private' && (
                    <>
                    <Typography sx={{ color: 'black', fontSize: 12, fontFamily: 'Lexend', fontWeight: '700', textTransform: 'none', letterSpacing: 2, wordWrap: 'break-word', marginTop: 2 }}>Add to a Team</Typography>
                    <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
                        <FormControl fullWidth>
                                <Select
                                    value={selectedTeam}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setSelectedTeam(value);
                                    }}
                                    IconComponent={CustomIcon}
                                    displayEmpty
                                    inputProps={{
                                        style: { color: "#4F4F4F" },
                                    }}
                                    MenuProps={{
                                        PaperProps: {
                                          style: {
                                            backgroundColor: 'white',
                                            maxHeight: 300,
                                            overflow: 'auto',
                                            maxWidth: '20%',
                                            width: '20%',
                                          },
                                        },
                                      }}
                                      sx={{
                                        color: "black",
                                        background: "white",
                                        borderRadius: "16px",
                                        border: "1px solid #C4CAD4",
                                        "&:before, &:after": {
                                          display: "none",
                                        },
                                        "& fieldset": {
                                          border: "none !important",
                                        },
                                      }}
                                >
                                    {teamsInformation?.length > 0 && teamsInformation.map((team) => (
                                        <MenuItem 
                                            key={team.team_id} 
                                            value={team.team_id} 
                                            disabled={userTeams && userTeams.some(userTeam => userTeam.team_id === team.team_id)} // Disable if user is in team
                                            sx={{
                                                background: "white",
                                                color: "black",
                                                "&:hover": {
                                                  background: "#F7F7F7",
                                                  cursor: "pointer"
                                                },
                                              }}
                                        >
                                            <Typography
                                                sx={{
                                                    color: "black",
                                                    fontSize: "12px",
                                                    whiteSpace: "normal",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    wordBreak: "break-word"
                                                }}
                                            >
                                                {team.team_name}
                                            </Typography>
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                        <Button
                            onClick={handleAddToTeam}
                            disabled={!selectedTeam || addingToTeam || loadingCompanyData}
                            sx={{
                                color: 'black',
                                fontFamily: "Poppins",
                                fontWeight: '500',
                                border: '1px solid black',
                                borderRadius: 50,
                                lineHeight: "18px",
                                letterSpacing: "0.05em",
                                textTransform: 'capitalize',
                                padding: '6px 12px',
                                '&.Mui-disabled': {
                                color: 'black', // Ensure text remains black when disabled
                                borderColor: '#000', // Ensure border remains visible
                                opacity: 0.5, // Optional: adjust opacity to indicate disabled state
                                },
                                transition: "all 0.3s",
                                "&:hover": {
                                cursor: "pointer",
                                background: "black",
                                borderColor: "black",
                                color: "white",
                                },
                                "&:hover *": {
                                color: "white",
                                },
                                "&:hover svg *": {
                                stroke: "white",
                                },
                            }}
                            >
                            {addingToTeam ? <CircularProgress size={15} style={{ color: "black" }} /> : "Add"}
                        </Button>
                    </Box>

                    <Typography sx={{ color: 'black', fontSize: 12, fontFamily: 'Lexend', fontWeight: '700', textTransform: 'none', letterSpacing: 2, wordWrap: 'break-word', marginTop: 2 }}>Assign Manager</Typography>
                    <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
                        <FormControl fullWidth>
                                <Select
                                    value={selectedManager || ""}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setSelectedManager(value);
                                    }}
                                    IconComponent={CustomIcon}
                                    displayEmpty
                                    inputProps={{
                                        style: { color: "#4F4F4F" },
                                    }}
                                    MenuProps={{
                                        PaperProps: {
                                          style: {
                                            backgroundColor: 'white',
                                            maxHeight: 300,
                                            overflow: 'auto',
                                            maxWidth: '20%',
                                            width: '20%',
                                          },
                                        },
                                      }}
                                      sx={{
                                        color: "black",
                                        background: "white",
                                        borderRadius: "16px",
                                        border: "1px solid #C4CAD4",
                                        "&:before, &:after": {
                                          display: "none",
                                        },
                                        "& fieldset": {
                                          border: "none !important",
                                        },
                                      }}
                                >
                                    {/* Option to Remove Manager if user has a manager */}
                                    {selectedUser?.manager_user_id && (
                                        <MenuItem
                                            value=""
                                            onClick={() => setSelectedManager(null)} // Set manager to null to remove
                                            sx={{
                                                background: "white",
                                                color: "red",
                                                fontWeight: "bold",
                                                "&:hover": {
                                                    background: "#F7F7F7",
                                                    cursor: "pointer",
                                                },
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    color: "red",
                                                    fontSize: "12px",
                                                    whiteSpace: "normal",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    wordBreak: "break-word",
                                                }}
                                            >
                                                Remove Manager
                                            </Typography>
                                        </MenuItem>
                                    )}
                                    {internalCompanyMembers?.length > 0 && internalCompanyMembers?.map((member) => (
                                        <MenuItem 
                                            key={member.user_id} 
                                            value={member.user_id} 
                                            disabled={
                                                (selectedUser?.user_id === user?.sub && member.user_id === user?.sub) 
                                                    || (selectedUser?.user_id === member.user_id)
                                                        || checkCircularRelationship(selectedUser.user_id, member.user_id, internalCompanyMembers)
                                            }
                                            sx={{
                                                background: "white",
                                                color: "black",
                                                "&:hover": {
                                                  background: "#F7F7F7",
                                                  cursor: "pointer"
                                                },
                                              }}
                                        >
                                            <Typography
                                                sx={{
                                                    color: "black",
                                                    fontSize: "12px",
                                                    whiteSpace: "normal",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    wordBreak: "break-word"
                                                }}
                                            >
                                                {member?.full_name || "Unknown"}
                                            </Typography>
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                        <Button
                            onClick={handleSaveManager}
                            disabled={ savingManager || loadingCompanyData || selectedUser?.manager_user_id === selectedManager}
                            sx={{
                                color: 'black',
                                fontFamily: "Poppins",
                                fontWeight: '500',
                                border: '1px solid black',
                                borderRadius: 50,
                                lineHeight: "18px",
                                letterSpacing: "0.05em",
                                textTransform: 'capitalize',
                                padding: '6px 12px',
                                '&.Mui-disabled': {
                                color: 'black', // Ensure text remains black when disabled
                                borderColor: '#000', // Ensure border remains visible
                                opacity: 0.5, // Optional: adjust opacity to indicate disabled state
                                },
                                transition: "all 0.3s",
                                "&:hover": {
                                cursor: "pointer",
                                background: "black",
                                borderColor: "black",
                                color: "white",
                                },
                                "&:hover *": {
                                color: "white",
                                },
                                "&:hover svg *": {
                                stroke: "white",
                                },
                            }}
                            >
                            {savingManager ? <CircularProgress size={15} style={{ color: "black" }} /> : "Save"}
                        </Button>
                    </Box>

                    
                    <Typography sx={{ color: 'black', fontSize: 12, fontFamily: 'Lexend', fontWeight: '700', textTransform: 'capitalize', letterSpacing: 2, wordWrap: 'break-word', marginTop: 2 }}>Teams</Typography>
                    <Box sx={{ display: "flex", width: "100%", maxHeight: "300px", overflowY: "auto", flexDirection: "column" }}>
                        {userTeams && userTeams.map((team) => (
                            <Box key={team.team_id} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", borderBottom: '1px solid #ddd', paddingBottom: '8px', marginBottom: '8px', width: "100%" }}>
                                <Box sx={{ display: "flex", alignItems: "center", gap: "8px", width: "50%"}}>
                                    <Typography sx={{ color: 'black', fontSize: 12, fontFamily: 'Lexend', fontWeight: '700', letterSpacing: 2 }}>{team.team_name}</Typography>
                                    <Typography sx={{ color: '#4F4F4F', fontSize: 12, fontFamily: 'Lexend', fontWeight: '700', letterSpacing: 2 }}>{team.role_in_team === 'team_admin' ? "(Admin)" : "(User)"}</Typography>
                                </Box>
                                <Box sx={{ display: "flex", gap: "8px", width: "50%" }}>
                                    <Button
                                        onClick={() => handleRemoveTeam(team.team_id)}
                                        disabled={removingTeam === team.team_id || loadingCompanyData}
                                        sx={{ border: "1px solid black", borderRadius: 50, display: "flex", alignItems: "center", gap: "8px", 
                                            transition: "all 0.3s",
                                            "&:hover": {
                                            cursor: "pointer",
                                            background: "black",
                                            borderColor: "black",
                                            color: "white",
                                            },
                                            "&:hover *": {
                                            color: "white",
                                            },
                                            "&:hover svg *": {
                                            stroke: "white",
                                            },
                                        }}
                                    >
                                        <Typography sx={{ color: 'black', fontSize: 12, fontFamily: 'Poppins', fontWeight: '500', textTransform: 'capitalize', letterSpacing: "0.05em", lineHeight: "18px" }}>
                                            {removingTeam === team.team_id ? <CircularProgress size={10} style={{ color: "black" }} /> : "Remove"}
                                        </Typography>
                                    </Button>
                                    {team.role_in_team === 'team_member' ? (
                                        <Button
                                            onClick={() => handleMarkAsAdmin(team.team_id)}
                                            disabled={markingAsAdmin === team.team_id || loadingCompanyData  }
                                            sx={{ border: "1px solid black", borderRadius: 50, display: "flex", alignItems: "center", gap: "8px", color: "black",
                                                '&.Mui-disabled': {
                                                    backround: "#F0F0F0",
                                                    color: 'gray', // Gray text
                                                    borderColor: 'gray', // Optional: change border color when disabled
                                                },
                                                transition: "all 0.3s",
                                                "&:hover": {
                                                cursor: "pointer",
                                                background: "black",
                                                borderColor: "black",
                                                color: "white",
                                                },
                                                "&:hover *": {
                                                color: "white",
                                                },
                                                "&:hover svg *": {
                                                stroke: "white",
                                                }, 
                                            }}
                                        >
                                            <Typography sx={{ fontSize: 12, fontFamily: 'Poppins', fontWeight: '500', textTransform: 'capitalize', letterSpacing: "0.05em", lineHeight: "18px" }}>
                                                {markingAsAdmin === team.team_id ? <CircularProgress size={10} style={{ color: "black" }} /> : "Mark as Admin"}
                                            </Typography>
                                        </Button>
                                    ) : (
                                        <Button
                                            onClick={() => handleMarkAsTeamMember(team.team_id)}
                                            disabled={removingAdminRole === team.team_id || loadingCompanyData}
                                            sx={{ border: "1px solid black", borderRadius: 50, display: "flex", alignItems: "center", gap: "8px", color: "black",
                                                '&.Mui-disabled': {
                                                    backround: "#F0F0F0",
                                                    color: 'gray', // Gray text
                                                    borderColor: 'gray', // Optional: change border color when disabled
                                                },
                                                transition: "all 0.3s",
                                                "&:hover": {
                                                cursor: "pointer",
                                                background: "black",
                                                borderColor: "black",
                                                color: "white",
                                                },
                                                "&:hover *": {
                                                color: "white",
                                                },
                                                "&:hover svg *": {
                                                stroke: "white",
                                                }, 
                                            }}
                                        >
                                            <Typography sx={{ fontSize: 12, fontFamily: 'Poppins', fontWeight: '500', textTransform: 'capitalize', letterSpacing: "0.05em", lineHeight: "18px" }}>
                                               {removingAdminRole === team.team_id ? <CircularProgress size={10} style={{ color: "black" }} /> : "Remove Admin Role"}
                                            </Typography>
                                        </Button>
                                    )}
                                </Box>
                            </Box>
                        ))}
                    </Box>
                    </>
                )}

                {myPrivateUsers && selectedUser && selectedUser?.role && selectedUser?.role === 'private' && myPrivateUsers.includes(selectedUser?.user_id) && (
                    <>
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                            <Box sx={{ display: "flex", flexDirection: "column", gap: "8px", width: "100%" }}>
                                <Typography sx={{ color: 'black', fontSize: 12, fontFamily: 'Lexend', fontWeight: '700', textTransform: 'none', letterSpacing: 2, wordWrap: 'break-word', marginTop: 2 }}>Remove as Private User</Typography>
                                <Typography sx={{ color: 'black', fontSize: 12, fontFamily: 'Lexend', fontWeight: '500', textTransform: 'none', letterSpacing: 1.2, wordWrap: 'break-word' }}>Mark if you would like to keep the data and confirm. Then click 'remove' to proceed.</Typography>
                                {/* Keep Data Checkbox */}
                                <FormControlLabel
                                control={
                                    <Checkbox 
                                    checked={keepData} 
                                    onChange={handleKeepDataChange} 
                                    sx={{ color: 'black', '&.Mui-checked': { color: 'black' } }} 
                                    />
                                }
                                label={
                                    <Typography sx={{ color: 'black', fontSize: 12, fontFamily: 'Lexend', fontWeight: '500', letterSpacing: 1.2 }}>
                                    Keep the data shared between my private user and I.
                                    </Typography>
                                }
                                />
                                
                                {/* Confirm Removal Checkbox */}
                                <FormControlLabel
                                control={
                                    <Checkbox 
                                    checked={confirmChecked} 
                                    onChange={handleConfirmChange} 
                                    sx={{ color: 'black', '&.Mui-checked': { color: 'black' } }} 
                                    />
                                }
                                label={
                                    <Typography sx={{ color: 'black', fontSize: 12, fontFamily: 'Lexend', fontWeight: '500', letterSpacing: 1.2 }}>
                                    I understand and confirm this action.
                                    </Typography>
                                }
                                />
                            </Box>
                            <Button 
                                onClick={handleRemovePrivateUser}
                                disabled={!confirmChecked || loadingPrivateUserRemoval}
                                sx={{ border: "1px solid black", borderRadius: 50, display: "flex", alignItems: "center", gap: "8px", color: "black",
                                    '&.Mui-disabled': {
                                        backround: "#F0F0F0",
                                        color: 'gray', // Gray text
                                        borderColor: 'gray', // Optional: change border color when disabled
                                    }, 
                                    transition: "all 0.3s",
                                    "&:hover": {
                                    cursor: "pointer",
                                    background: "black",
                                    borderColor: "black",
                                    color: "white",
                                    },
                                    "&:hover *": {
                                    color: "white",
                                    },
                                    "&:hover svg *": {
                                    stroke: "white",
                                    },
                                }}
                            >
                                <Typography sx={{ fontSize: 12, fontFamily: 'Poppins', fontWeight: '500', textTransform: 'capitalize', letterSpacing: "0.05em", lineHeight: "18px" }}>
                                    {loadingPrivateUserRemoval ? <CircularProgress size={10} style={{ color: "black" }} /> : "Remove" }
                                </Typography>
                            </Button>
                        </Box>
                    </>
                )}
                </Box>
            </Box>
        </Modal>
    );
};

export default ActionOnUserModal;